<template>
  <v-container id="role" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Role Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>Role Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>Role Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-data-table
      :headers="columns"
      :items="roles"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat style="height: 64px; padding-left: 0px !important">
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch()"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col>
              <router-link
                v-if="modulePermission.Create"
                :to="{ name: 'Add Role' }"
              >
                <v-btn color="primary float-right" dark class="mt-4"
                  >Add Role</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Confirm Delete</v-card-title
              ><v-card-text class="text-center"
                >Are you sure to delete record ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" depressed @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="deleteItemConfirm"
                  >Delete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Role',
            params: { id: item._id },
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Role',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
        <v-icon v-if="modulePermission.Delete" @click="deleteRole(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <template>
      <div class="custom_pagination_design">
        <span>
          Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
          <b>{{ totalRecord }}</b> Records
        </span>

        <v-pagination
          class="float-right"
          v-model="page"
          :length="totalPages"
          total-visible="7"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="handlePageChange"
        ></v-pagination>
      </div>
    </template>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      moduleName: "Roles",
      modulePermission: [],
      search: null,
      roles: [],
      dialogDelete: false,
      deleteRecord: "",
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px",
        },
        { sortable: false, text: "Id", value: "_id", sortable: false },
        { sortable: false, text: "Name", value: "name" },
      ];
    },
  },
  methods: {
    getRoles() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "role/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.roles = response.data.roles.data;
            this.loading = false;
            this.totalPages = response.data.roles.last_page;
            this.startRecord = response.data.roles.from;
            this.endRecord = response.data.roles.to;
            this.totalRecord = response.data.roles.total;
            this.numbers = [];
            for (
              let num = response.data.roles.from;
              num <= response.data.roles.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getRoles();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      this.dialogDelete = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "role/delete/" + this.deleteRecord
        )
        .then((response) => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getRoles();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteRole(data) {
      this.dialogDelete = true;
      this.deleteRecord = data._id;
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getRoles();
    },
  },
  mounted() {
    this.getRoles();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  },
};
</script>

<style>
</style>
